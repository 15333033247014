<template>
    <div class="login" data-lbg="teal">
        <div class="l-block toggled" id="l-login">
            <div class="lb-header palette-Teal bg">
                <i class="zmdi zmdi-account-circle"></i>
                Hi there! Please Sign in here
            </div>

            <div class="lb-body">                

                <form @submit.prevent="changePassword">
                    <div class="form-group">
                        <label>New Password</label>
                        <input type="password" class="form-control" v-model="password.npassword">
                    </div>
                    <div class="form-group">
                        <label>Repeat Password</label>
                        <input type="password" class="form-control" v-model="password.rpassword">
                    </div>
                    <button type="submit" class="btn palette-Teal bg">Change Password</button>
                </form>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        data(){
            return {
                password: {
                    userId: this.$store.state.user.userId,
                    npassword: "",
                    rpassword: "",
                }
            }
        },
        methods: {

            changePassword(){

                this.$http.post('/users/setpassword', this.password).then(response => {
                    if (response.data.code === 200) {

                        // redirect to the dashboard
                        this.$router.push('/')

                        // show the notify success notification
                        this.$notify.success(response.data.reason)

                    } else if (response.data.code === 400) {
                        
                        // show the notify error notification
                        this.$notify.error(response.data.reason)

                    }
                })
            },


        }
    }
</script>